import React, { useState } from 'react';

import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-thumbnail.scss';
import "lightgallery/css/lg-video.css";


import horizonthumb from '../img/game/horizonthumb.jpg';
import horizoncover from '../img/game/horizoncover.jpg';
import shooterthumb from '../img/game/shooterthumb.jpg';
import shootercover from '../img/game/shootercover.jpg';
import game3 from '../img/game/03.jpg';
import game4 from '../img/game/04.jpg';
import game7 from '../img/game/07.jpg';
import game8 from '../img/game/08.jpg';
import game9 from '../img/game/09.jpg';
import game11 from '../img/game/11.jpg';
import game12 from '../img/game/12.jpg';

import kikothumb from '../img/animation/kikothumb.jpg';
import kikocover from '../img/animation/kikocover.jpg';
import unrealthumb from '../img/animation/unrealthumb.jpg';
import unrealcover from '../img/animation/unrealcover.jpg';
import animation2 from '../img/animation/14.jpg';
import animation3 from '../img/animation/15.jpg';
import animation4 from '../img/animation/16.jpg';
import animation7 from '../img/animation/19.jpg';
import animation8 from '../img/animation/20.jpg';
import animation9 from '../img/animation/21.jpg';
import animation10 from '../img/animation/22.jpg';

import defence1 from '../img/defence/23.jpg';
import defence2 from '../img/defence/24.jpg';
import defence3 from '../img/defence/25.jpg';
import defence4 from '../img/defence/26.jpg';
import defence5 from '../img/defence/27.jpg';
import defence6 from '../img/defence/28.jpg';

import covidcover from '../img/vr/covidcover.jpg'
import covidthumb from '../img/vr/covidthumb.jpg'
import forkcover from '../img/vr/forkcover.jpg'
import forkthumb from '../img/vr/forkthumb.jpg'
import yangincover from '../img/vr/yangincover.jpg'
import yanginthumb from '../img/vr/yanginthumb.jpg'


export default function Games() {
  const [activeTab, setActiveTab] = useState('game');

  const changeActiveTab = (e) => {
    const tabid = e.target.getAttribute('tabid');
    setActiveTab(tabid);
  }

  return (
    <div className='games'>
        <div className='games-header'>
            <div className='games-header_text'>Portfolio</div>
            <div className='games-header_line'></div>
        </div>

        <div className='gamescontent'>

          <div className='gamescontent-tabs'>
            <div tabid="game" className={`gamescontent-tabs_button ${activeTab === 'game' ? 'activeTab' : ''}`} onClick={changeActiveTab}>Game</div>
            <div tabid="animation" className={`gamescontent-tabs_button ${activeTab === 'animation' ? 'activeTab' : ''}`} onClick={changeActiveTab}>Animation</div>
            <div tabid="defence" className={`gamescontent-tabs_button ${activeTab === 'defence' ? 'activeTab' : ''}`} onClick={changeActiveTab}>Simulation</div>
            <div tabid="vr" className={`gamescontent-tabs_button ${activeTab === 'vr' ? 'activeTab' : ''}`} onClick={changeActiveTab}>VR</div>
          </div>

          <div className='gamescontent-game' style={activeTab==="game"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail, lgVideo]}>
              <a
                href="https://www.youtube.com/watch?v=vaXeEKTd7ZU"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=vaXeEKTd7ZU"
                data-poster={horizoncover}
              >
                <img src={horizonthumb} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game3} href={game3}>
                <img src={game3} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game4} href={game4}>
                <img src={game4} alt=""/>
              </a>
              <a
                href="https://www.youtube.com/watch?v=VQsdGtn1kDU"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=VQsdGtn1kDU"
                data-poster={shootercover}
              >
                <img src={shooterthumb} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game7} href={game7}>
                <img src={game7} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game8} href={game8}>
                <img src={game8} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game9} href={game9}>
                <img src={game9} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game11} href={game11}>
                <img src={game11} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={game12} href={game12}>
                <img src={game12} alt=""/>
              </a>
            </LightGallery>
          </div>

          <div className='gamescontent-animation' style={activeTab==="animation"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail, lgVideo]}>
              <a
                href="https://www.youtube.com/watch?v=cCwF-DDzS6M"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=cCwF-DDzS6M"
                data-poster={kikocover}
              >
                <img src={kikothumb} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation2} href={animation2}>
                <img src={animation2} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation3} href={animation3}>
                <img src={animation3} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation4} href={animation4}>
                <img src={animation4} alt=""/>
              </a>
              <a
                href="https://www.youtube.com/watch?v=BfhVso4bFAI"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=BfhVso4bFAI"
                data-poster={unrealcover}
              >
                <img src={unrealthumb} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation7} href={animation7}>
                <img src={animation7} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation8} href={animation8}>
                <img src={animation8} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation9} href={animation9}>
                <img src={animation9} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={animation10} href={animation10}>
                <img src={animation10} alt=""/>
              </a>
            </LightGallery>
          </div>


          <div className='gamescontent-defence' style={activeTab==="defence"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail]}>
              <a data-lg-size="2560-1000" data-lg-thumb={defence1} href={defence1}>
                <img src={defence1} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={defence2} href={defence2}>
                <img src={defence2} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={defence3} href={defence3}>
                <img src={defence3} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={defence4} href={defence4}>
                <img src={defence4} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={defence5} href={defence4}>
                <img src={defence5} alt=""/>
              </a>
              <a data-lg-size="2560-1000" data-lg-thumb={defence6} href={defence6}>
                <img src={defence6} alt=""/>
              </a>
            </LightGallery>
          </div>


          <div className='gamescontent-vr' style={activeTab==="vr"?{display:"flex"}:{display:"none"}}>
            <LightGallery elementClassNames='gamescontent-game_galleryholder' speed={500} plugins={[lgThumbnail, lgVideo]}>
              <a
                href="https://www.youtube.com/watch?v=X6S2Z1XWPAk"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=X6S2Z1XWPAk"
                data-poster={yangincover}
              >
                <img src={yanginthumb} alt=""/>
              </a>
              <a
                href="https://www.youtube.com/watch?v=6Xb5C7NgBhY"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=6Xb5C7NgBhY"
                data-poster={forkcover}
              >
                <img src={forkthumb} alt=""/>
              </a>
              <a
                href="https://www.youtube.com/watch?v=3KrWubxhjOo"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=3KrWubxhjOo"
                data-poster={covidcover}
              >
                <img src={covidthumb} alt=""/>
              </a>
            </LightGallery>
          </div>


        </div>
    </div>
  )
}
