import React from 'react';
import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => <div><img src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="marker" />{text}</div>;

const SimpleMap = ({ lat, lng }) => {

  return (
    <div className='contact_contactcontent-map'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDZ0cQYuTOwfs6ueFI7RC_mNvhAgYhWBxg' }}
        defaultCenter={{ lat, lng }}
        defaultZoom={16}
      >
        <Marker
          lat={lat}
          lng={lng}
          text="GFDS"
        />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
