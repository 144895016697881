import React from 'react'
import aboutimage from '../img/about-image.jpg'

export default function About() {
  return (
      <div className='about'>
          <div className='about-header'>
              <div className='about-header_text'>About</div>
              <div className='about-header_line'></div>
          </div>

          <div className='aboutcontent'>

            <div className='about-text'>
              <p>At GFDS, we are a dynamic game and animation studio driven by a passion for innovation, creativity, and the boundless possibilities at the intersection of technology and art.</p>
              <br/>
              <p>Our experienced team thrives on crafting immersive gaming experiences that push the boundaries of what's possible in code, while simultaneously breathing life into captivating animated tales that resonate with audiences.</p>
              <br/>
              <p>We believe in the power of storytelling through both pixels and frames, where epic games and animated adventures converge. At GFDS, we don't just create entertainment; we redefine it bridging the realms of gaming and animation to unlock the limitless potential of imagination. </p>

            </div>

            <div className='about-image'>
              <img src={aboutimage} alt="" />
            </div>

          </div>
      </div>
  )
}
