import React from 'react';
import Header from './components/Header';
import "./index.scss";
import Intro from './components/Intro';
import About from './components/About';
import Games from './components/Games';
import Team from './components/Team';
// import Careers from './components/Careers';
import Contact from './components/Contact';
import Reserved from './components/Reserved';
import Upcoming from './components/Upcoming';

function App() {
  return (
    <>
      <Header/>
      <Intro/>
      <Upcoming/>
      <About/>
      <Games/>
      <Team/>
      {/* <Careers/> */}
      <Contact/>
      <Reserved/>
    </>
  );
}

export default App;
