import React from 'react'
import { useState } from 'react';
import bg from '../img/teambg.png'
import team1 from '../img/team/gfds-team-01.jpg';
import team3 from '../img/team/gfds-team-03.jpg';
import team5 from '../img/team/gfds-team-05.jpg';
import team6 from '../img/team/gfds-team-06.jpg';
import team7 from '../img/team/gfds-team-07.jpg';
import team8 from '../img/team/gfds-team-08.jpg';
import team9 from '../img/team/gfds-team-09.jpg';
import team10 from '../img/team/gfds-team-10.jpg';
import team12 from '../img/team/gfds-team-12.jpg';
import team13 from '../img/team/gfds-team-13.jpg';
import team14 from '../img/team/gfds-team-14.jpg';

export default function Team() {

  const [count, setCount] = useState(0);

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }

  return (
    <div className='team'>
        <div className='team-header'>
            <div className='team-header_text'>Company & Team</div>
            <div className='team-header_line'></div>
        </div>
        <div className='teamcontent'>
          <div className='teamcontent_text'>Established in 2013, our company continues its existence by now producing games and animations. With our experienced team, we always prioritize innovation and aesthetics as we walk the path of creativity.</div>

          <div className='teamcontent_showcase' style={{backgroundImage: `url(${bg})`}} onClick={() => setCount(count + 1)}>
            <img src={team1}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team3}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team5}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team6}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team7}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team8}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team9}  alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team10} alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team12} alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team13} alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
            <img src={team14} alt="" style={{top:getRandomInt(1,35)+'%',left:getRandomInt(1,15)+'%',width:getRandomInt(100,200)}}/>
          </div>

          <div className='teamcontent_showcase-mobile' style={{backgroundImage: `url(${bg})`}} onTouchEnd={() => setCount(count + 1)}>
            <img src={team1}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team3}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team5}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team6}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team7}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team8}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team9}  alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team10} alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team12} alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team13} alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
            <img src={team14} alt="" style={{top:getRandomInt(1,70)+'%',left:getRandomInt(1,10)+'%',width:getRandomInt(20,70)}}/>
          </div>

          
          
        </div>
    </div>
  )
}
