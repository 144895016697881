import React from 'react'
import SimpleMap from './Map'
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import css from "../index.scss";



export default function Contact() {

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  }


  return (
    <div className='contact'>
        <div className='contact-header'>
          <div className='contact-header_text'>Contact</div>
          <div className='contact-header_line'></div>
        </div>
        <div className='contactcontent'>
          <div className='contact_contactcontent-address'>
            <div style={{fontWeight:'500',fontSize:'1.5em'}}>If you want to get to know us better, you can follow our social media accounts or get in touch with us.</div>
            <a href="mailto:gfds@gfds-inc.com">gfds@gfds-inc.com</a>
            <div>+90 (312) 285 25 20</div>
            <div>METU Teknokent Information Innovation Center
            Mustafa Kemal Mh., Dumlupınar Boulevard No:280 B/308
            Postal Code: 06530 Çankaya / ANKARA</div>
            <div className='contactcontent-social'>
              <FaLinkedinIn style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://www.linkedin.com/company/gfds-inc/')}/>
              <FaYoutube style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://www.youtube.com/channel/UC_zLDL_tOMWMgLBY4l0OJqw')}/>
              <FaInstagram style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://www.instagram.com/gfds_inc/')}/>
              <FaFacebookF style={{color:css.colorBlue}} size={26} onClick={() => openInNewTab('https://www.facebook.com/gfdsinc/?locale=tr_TR')}/>
              <FaXTwitter style={{color:css.colorBlue}} size={30} onClick={() => openInNewTab('https://twitter.com/gfds_tr')}/>
            </div>
          </div>
          <div className='splitter-vertical'></div>
          <SimpleMap lat={39.908672300407105} lng={32.750219044458774} />
        </div>
    </div>
  )
}
